import React, {Component} from 'react';
import ProductModal from './ProductModal';
import OptionsModal from "./OptionsModal";
import FxCurrencyFormat from "./currencyformat";

type Props = {
  idx: string;
  productData: any;
  spaceData: any;
  suggestedArticles: any;
  roomCode: string;
  rating: string;
  article: any;
  setRoomSelection: (roomCode: string, rating: string, productCode: string, index: number, quantity: number, reject: boolean) => void;
  itemIndex: number;
  getSelectedProductCode: (roomCode: string, rating: string, idx: number) => string | null;
  getPrice: (productCode: string) => { price: number, discount: number, currency: string };
};

type State = {
  modalOpen: boolean;
  optionsModalOpen: boolean;
};

class ProductCard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalOpen: false,
      optionsModalOpen: false,
    };
  }

  openModal = () => {
    this.setState({modalOpen: true});
  };

  closeModal = () => {
    this.setState({modalOpen: false});
  };

  setOptionsModalOpen = (open: boolean) => {
    this.setState({optionsModalOpen: open});
  };

  closeOptionsModal = () => {
    this.setState({optionsModalOpen: false});
  };

  handleSelect = (reject = false) => {
    const {roomCode, rating, setRoomSelection, itemIndex} = this.props;
    const {spaceProductData, spaceArticleData} = this.getSpaceData();
    if (spaceProductData) {
      setRoomSelection(roomCode, rating, spaceProductData.product_number, itemIndex, spaceArticleData.quantity, reject);
    }
  };

  getSpaceData() {
    const {article, suggestedArticles,roomCode, rating, itemIndex, productData, getSelectedProductCode} = this.props;
    const selectedProductCode = getSelectedProductCode(roomCode, rating, itemIndex);
    const articleData = suggestedArticles[rating]?.[itemIndex];
    let spaceArticleData: any;
    const articleType = article.type;

    if (articleType === 'multi_choice') {
      spaceArticleData = selectedProductCode && selectedProductCode !== 'rejected'
        ? articleData.value.find((product: any) => product.product_code === selectedProductCode)
        : articleData.value[0];
    } else {
      spaceArticleData = articleData.value;
    }

    const spaceProductData = productData.find((product: any) => product.product_number === spaceArticleData?.product_code);

    return {spaceArticleData, spaceProductData, selectedProductCode};
  }

  renderImageSection = (spaceProductData) => {
    return <div className="flex flex-col items-center">
      <img
        src={spaceProductData.product_images_urls?.[0]?.small_thumbnail_url}
        alt=""
        className="w-[80px] md:w-[100px] lg:w-[120px] h-[80px] md:h-[100px] lg:h-[120px] object-center object-contain cursor-pointer"
        onClick={this.openModal}
      />
      {spaceProductData.airfow_certificates && spaceProductData.airfow_certificates.length > 0 && (
        spaceProductData.airfow_certificates.map((cert: any) => (
          <div
            key={cert.label}
            className="w-[36px] sm:w-[44px] h-[36px] sm:h-[44px] mt-2 grid place-content-center text-[#1D1F22] text-[16px] rounded-[8px] font-semibold">
            <img src={cert.image_url} alt={cert.label}/>
          </div>
        ))
      )}
    </div>
  }

  renderButtons = (selectedProductCode, spaceArticleData, articleType) => {
    const rejected = selectedProductCode === 'rejected';
    const selectButtonLabel = selectedProductCode
      ? (rejected
        ? window.gettext('Rejected. Click to select')
        : window.gettext('Selected. Click to deselect'))
      : window.ngettext('Select this', 'Select these', spaceArticleData.quantity);

    const buttonList = [];

    buttonList.push(<button
      type='button'
      className={`text-sm text-${selectedProductCode ? 'fxgreen-dark' : '[#217CBF]'} font-semibold mr-3 mt-0`}
      onClick={() => this.handleSelect()}
    >
      {selectButtonLabel}
    </button>)

    if (articleType === 'multi_choice' && (!selectedProductCode || rejected) ) {
      buttonList.push(<button
        type='button'
        className={`text-sm text-${selectedProductCode ? 'fxgreen-dark' : '[#217CBF]'} font-semibold mr-3 mt-0`}
        onClick={() => this.setOptionsModalOpen(true)}
      >
        {window.gettext('View options')}
      </button>)
    }

    if (!selectedProductCode) {
      buttonList.push(<button
        type='button'
        className="text-sm text-[#217CBF] mt-0"
        onClick={() => this.handleSelect(true)}
      >
        {window.gettext("Thank you, don't need this")}
      </button>)
    }

    return <div className='flex flex-col items-start md:flex-row md:space-x-4 space-y-2 md:space-y-0'>
      {/* <div class="flex flex-col md:flex-row md:space-x-4 space-y-2 md:space-y-0">
        <button class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Button 1</button>
        <button class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">Button 2</button>
        <button class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">Button 3</button>
      </div> */}

      {buttonList}
    </div>
  }

  renderProductInfoSection = () => {
    const {spaceArticleData, spaceProductData, selectedProductCode} = this.getSpaceData();
    const {idx, article, getPrice} = this.props;
    const price = getPrice(spaceProductData.product_number);
    const discountedPrice = (price ? price.price : 0) * (1 - price.discount / 100);

    return <div className="pl-0 pt-[12px] lg:pl-[40px] sm:pt-0 text-left">
      <p className="font-semibold text-[#1D1F22] text-[16px]">
        {spaceArticleData.quantity} stk. {spaceProductData.name}
      </p>
      <div className="prose max-w-4xl space-y-3 text-sm col-start-1 product-description">
        {spaceProductData.features && (
          <div dangerouslySetInnerHTML={{__html: spaceProductData.features}}/>
        )}
        <p className="text-sm text-gray-600">
          Pris hos Megaflis: <span
          className="ml-2 font-extrabold">{FxCurrencyFormat(discountedPrice)}</span>
          {price.discount > 0 && (
            <span className="line-through ml-2">{FxCurrencyFormat(price.price)}</span>
          )}
        </p>
        {this.renderButtons(selectedProductCode, spaceArticleData, article.type)}
      </div>
    </div>
  }

  render() {
    const {idx, article} = this.props;
    const {modalOpen, optionsModalOpen} = this.state;
    const {spaceArticleData, spaceProductData, selectedProductCode} = this.getSpaceData();

    if (article.type === 'text') {
      return (
        <div key={`pcard${idx}`}>
          <div
            className="border-[#EBEBF0] border rounded-[12px] w-full relative bg-white mb-4 transform transition duration-500 hover:scale-100">
            <div
              className="prose max-w-4xl p-[20px] text-gray-700 text-lg text-left"
              dangerouslySetInnerHTML={{__html: article.value}}
            />
          </div>
        </div>
      );
    }

    if (!spaceProductData) {
      return null;
    }

    return (
      <div key={`pcard${idx}`}>
        <div
          className="border-[#EBEBF0] border rounded-[12px] w-full relative bg-white mb-4 transform transition duration-500 hover:scale-105">
          <button type="button" className="grid sm:flex w-full p-[20px]">
            <div className="flex justify-between w-full">
              <div className="flex gap-4">
                {this.renderImageSection(spaceProductData)}
                {this.renderProductInfoSection()}
              </div>
            </div>
          </button>
        </div>
        {modalOpen && <ProductModal productData={spaceProductData} closeModal={this.closeModal}/>}
        {optionsModalOpen && (
          <OptionsModal
            article={this.props.suggestedArticles[this.props.rating]?.[this.props.itemIndex]}
            productData={this.props.productData}
            roomCode={this.props.roomCode}
            rating={this.props.rating}
            itemIndex={this.props.itemIndex}
            setRoomSelection={this.props.setRoomSelection}
            getPrice={this.props.getPrice}
            closeModal={this.closeOptionsModal}
          />
        )}
      </div>
    );
  }
}

export default ProductCard;

import React from 'react';
import FxCurrencyFormat from './currencyformat';

type Props = {
  article: any;
  productData: any;
  roomCode: string;
  rating: string;
  itemIndex: number;
  setRoomSelection: (roomCode: string, rating: string, productCode: string, index: number, quantity: number) => void;
  getPrice: (productCode: string) => { price: number, discount: number, currency: string };
  closeModal: () => void;
};

const OptionsModal: React.FC<Props> = ({ article, productData, roomCode, rating, itemIndex, setRoomSelection, getPrice, closeModal }) => {
  const handleSelect = (productCode: string, quantity: number) => {
    setRoomSelection(roomCode, rating, productCode, itemIndex, quantity);
    closeModal();
  };

  const handleModalClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent clicks on the modal content from closing the modal
  };

  return (
    <div className="absolute top-0 left-0 pt-20 md:mt-0 w-full h-full z-50 bg-black bg-opacity-50 flex justify-center items-center" onClick={closeModal}>
      <div className="bg-white rounded-lg p-4 max-w-2xl w-full border" onClick={handleModalClick}>
        <h2 className="text-xl font-semibold mb-4">Alternativer</h2>
        <div className="grid gap-4">
          {article.value.map((option: any, index: number) => {
            const productInfo = productData.find((product: any) => product.product_number === option.product_code);
            const price = getPrice(option.product_code);
            const discountedPrice = price.price * (1 - price.discount / 100);

            return (
              <div
                key={index}
                className="border p-4 rounded-lg flex items-center justify-between cursor-pointer hover:bg-gray-100"
                onClick={() => handleSelect(option.product_code, option.quantity)}
              >
                <div className="flex items-center gap-4">
                  <img src={productInfo.product_images_urls?.[0]?.small_thumbnail_url} alt={productInfo.name} className="w-16 h-16 object-contain"/>
                  <div className='text-left'>
                    <h3 className="text-md text-black font-semibold">{productInfo.name}</h3>
                    <p className="text-sm text-gray-600">{option.quantity} stk.</p>
                    <p className="text-sm text-gray-600">
                      Pris hos Megaflis: <span className="font-extrabold">{FxCurrencyFormat(discountedPrice)}</span>
                      {price.discount > 0 && (
                        <span className="line-through ml-2">{FxCurrencyFormat(price.price)}</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <button className="mt-3 w-full bg-gray-100 text-gray-600 font-semibold py-3 px-5 rounded-lg hover:bg-gray-200 hover:text-gray-800 
        transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
        onClick={closeModal}>
          Close
        </button>

      </div>
    </div>
  );
};

export default OptionsModal;
